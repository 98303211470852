<template>
  <div>
    <div class="propety">
      <Property-Week v-if="availabilities&&availabilities.requirement" ref="retaWeek" :data="availabilities"></Property-Week>
      <div style="min-height: 600px">
        <el-form :model="data" ref="ListingForm" :rules="rules">
          <div class="pages">
            <div class="form_in">
              <!-- Location Details -->
              <div>
                <div class="top-prodect" @click="locationShow = !locationShow">
                  <span class="title-text">Location Details</span>
                  <i class="el-icon-arrow-down" v-if="locationShow"></i>
                  <i class="el-icon-arrow-right" v-if="!locationShow"></i>
                </div>
                <div v-if="locationShow">
                  <div class="set_width">
                    <div class="propety_row">
                      <div class="propety_type">
                        <el-form-item prop="address" :rules="rules.required">
                          <p class="propety-title">Property Address</p>
                          <el-input v-model="data.address"></el-input>
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <el-form-item prop="area" :rules="rules.required">
                          <p class="propety-title">Area</p>
                          <el-select v-model="data.area" placeholder="Please choose">
                            <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                  <div class="check-row row">
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.waterfront">Waterfront</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.water_view">Water Views</el-checkbox-button>
                    </div>
                  </div>
                  <div class="set_width">
                    <div class="propety_row">
                      <div class="propety_type">
                        <el-form-item prop="distance_to_beach">
                          <p class="propety-title">
                            Distance to Nearest Beach (miles)
                          </p>
                          <el-input v-model="data.distance_to_beach" placeholder></el-input>
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <el-form-item prop="distance_to_the_hub">
                          <p class="propety-title">
                            Distance to C&C Office (miles)
                          </p>
                          <el-input v-model="data.distance_to_the_hub" placeholder></el-input>
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <el-form-item prop="distance_to_the_hub">
                          <p class="propety-title">
                            Guidebook Link
                          </p>
                          <el-input v-model="data.guidebook_link" placeholder></el-input>
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <el-form-item prop="distance_to_the_hub">
                          <p class="propety-title">
                          Virtual Tour Link
                          </p>
                          <el-input v-model="data.virtual_tour_link" placeholder />
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Descriptions -->
              <div>
                <div class="top-prodect" @click="decsShow = !decsShow">
                  <span class="title-text">Descriptions</span><i class="el-icon-arrow-down" v-if="decsShow"></i>
                  <i class="el-icon-arrow-right" v-if="!decsShow"></i>
                </div>
                <div v-if="decsShow">
                  <div class="set_width">
                    <el-form-item prop="headline" :rules="rules.required">
                      <p class="propety-title">
                        Headline (required for some websites)
                      </p>
                      <el-input v-model="data.headline" placeholder></el-input>
                    </el-form-item>
                    <el-form-item prop="description" :rules="rules.required">
                      <p class="propety-title">Main Description</p>
                      <el-input v-model="data.description" type="textarea" resize="none" :autosize="{ minRows: 1 }"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <p class="propety-title">First Floor</p>
                      <el-input v-model="data.first_floor" type="textarea" resize="none" :autosize="{ minRows: 1 }"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <p class="propety-title">Second Floor</p>
                      <el-input v-model="data.second_floor" type="textarea" resize="none" :autosize="{ minRows: 1 }"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <p class="propety-title">Lower Level</p>
                      <el-input v-model="data.lower_level" type="textarea" resize="none" :autosize="{ minRows: 1 }"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <p class="propety-title">Cottage / Other Structure</p>
                      <el-input v-model="data.other_structure" type="textarea" resize="none" :autosize="{ minRows: 1 }"></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <!-- General Information  -->
              <div>
                <div class="top-prodect" @click="generalShow = !generalShow">
                  <span class="title-text">General Information</span
                  ><i class="el-icon-arrow-down" v-if="generalShow"></i>
                  <i class="el-icon-arrow-right" v-if="!generalShow"></i>
                </div>
                <div v-if="generalShow">
                  <div class="set_width" style="">
                    <div class="propety_row" style="margin-botton: 20px">
                      <div class="propety_type">
                        <el-form-item prop="key_number">
                          <p class="propety-title">Key Number</p>
                          <el-input v-model="data.key_number" />
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <el-form-item>
                          <p class="propety-title">Listing Priority</p>
                          <el-select v-model="data.priority" placeholder="Please choose">
                            <el-option v-for="item in propertyPriorityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <el-form-item prop="capacity" :rules="rules.required">
                          <p class="propety-title">Max Sleeping Capacity</p>
                          <el-select v-model="data.capacity"  placeholder="Please choose">
                            <el-option v-for="item in 100" :key="item" :label="item" :value="item"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <p class="propety-title">House Phone Number</p>
                        <el-input v-model="data.house_phone" type="tel" maxlength="22"  @input="(e) => (data.house_phone = changePhone(e))"/>
                      </div>
                    </div>
                  </div>
                  <div class="check-row row">
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.air_conditioning">Air Conditioning</el-checkbox-button>
                      <div v-show="data.air_conditioning" class="show-margin">
                        <p class="propety-title">A/C Type</p>
                        <el-select v-model="data.ac_types" multiple  placeholder="Please choose">
                          <el-option v-for="item in acTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.clothes_dryer">Dryer (clothes)</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.fan">Fans</el-checkbox-button>
                      <div v-show="data.fan" class="show-margin">
                        <p class="propety-title">Quantity</p>
                        <el-select v-model="data.fan_quantity" placeholder>
                          <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.fireplace">Fireplaces (working)</el-checkbox-button>
                      <div v-show="data.fireplace" class="show-margin">
                        <p class="propety-title">Quantity</p>
                        <el-select v-model="data.fireplace_quantity" placeholder="">
                          <el-option v-for="item in 100" :key="item" :label="item" :value="item" >
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.gym">Fitness Room / Gym</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.hair_dryer">Hair Dryer</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.heating">Heating</el-checkbox-button>
                      <div v-show="data.heating" class="show-margin">
                        <p class="propety-title">Heating Type</p>
                        <el-select v-model="data.heating_type" placeholder="Please choose">
                          <el-option v-for="item in heatingTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.iron">Iron</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.ironing_board">Ironing Board</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.jacuzzi">Jacuzzi</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.linen">Linens Provided</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.parking">Off-street Parking</el-checkbox-button>
                      <div v-show="data.parking" class="show-margin">
                        <p class="propety-title">Quantity</p>
                        <el-select v-model="data.parking_quantity" placeholder="">
                          <el-option v-for="item in 100" :key="item" :label="item" :value="item">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.washing_machine">Washing Machine</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.wifi">Wifi</el-checkbox-button>
                      <div v-show="data.wifi" class="show-margin">
                        <p class="propety-title">Wifi Network</p>
                        <el-input v-model="data.wifi_network_name" placeholder></el-input>
                      </div>
                      <div v-show="data.wifi" class="show-margin">
                        <el-form-item prop="wifi_password">
                          <p class="propety-title">Wifi Password</p>
                          <el-input v-model="data.wifi_password" placeholder></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.safe">Safe</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.towel">Towels Provided</el-checkbox-button>
                    </div>
                  </div>
                  <div class="set_width">
                    <div class="propety_row">
                      <div class="propety_type">
                        <el-form-item>
                          <p class="propety-title">
                            MA Occupancy Tax Certificate Number
                          </p>
                          <el-input v-model="data.occupancy_tax_number" placeholder oninput="value=value.replace(/[\W]/g,'')" ></el-input>
                        </el-form-item>
                      </div>
                      <div class="propety_type">
                        <div class="propety_row">
                          <div class="propety_type">
                            <el-form-item prop="year_built">
                              <p class="propety-title">Year Built</p>
                              <el-input v-model="data.year_built" placeholder type="number" maxlength="4"></el-input>
                            </el-form-item>
                          </div>
                          <div class="propety_type">
                            <el-form-item prop="year_renovated">
                              <p class="propety-title">Year Renovated</p>
                              <el-input v-model="data.year_renovated" placeholder type="number" maxlength="4"></el-input>
                            </el-form-item>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="propety_type">
                      <p class="upload_file" >
                        <el-upload class="upload-demo" v-loading="loadingFile" action="file" :http-request="uploadFile" :show-file-list="false" >
                          <el-button slot="trigger" size="small" type="primary" >
                            <img src="../../assets/img/file.png" alt />
                          </el-button>
                        </el-upload>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a :href="fileName == 'Attached Files'?'javascript:void(0);':fileUrl" :target="fileName == 'Attached Files'?'':'_blank'" class="tip" :style="{ color: fileName == 'Attached Files' ? '#222' : '#678993', }">{{ fileName }}</a>
                      </p>
                    </div>
                    <div class="propety_row">
                      <div class="propety_type">
                        <el-form-item prop="other_rental_firms">
                          <p class="propety-title">Other Rental Firms</p>
                          <el-select v-model="data.other_rental_firms" placeholder="Please choose" multiple filterable  default-first-option>
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Dining -->
              <div>
                <div class="top-prodect" @click="diningShow = !diningShow">
                  <span class="title-text">Dining</span
                  ><i class="el-icon-arrow-down" v-if="diningShow"></i>
                  <i class="el-icon-arrow-right" v-if="!diningShow"></i>
                </div>
                <div v-if="diningShow">
                  <div class="check-row row">
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.dining_area">Dining Area</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.outdoor_dining_area">Outdoor Dining Area</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.counter_seating">Counter Seating</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.dining_room">Dining Room</el-checkbox-button>
                    </div>
                  </div>
                  <div class="set_width">
                    <div class="propety_row">
                      <div class="propety_type">
                        <el-form-item prop="seating" :rules="rules.required">
                          <p class="propety-title">Seating for</p>
                          <el-select v-model="data.seating" placeholder="Please choose" >
                            <el-option v-for="item in 99" :key="item" :label="item" :value="item"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Entertainment -->
              <div>
                <div class="top-prodect" @click="entertainShow = !entertainShow">
                  <span class="title-text">Entertainment</span><i class="el-icon-arrow-down" v-if="entertainShow"></i>
                  <i class="el-icon-arrow-right" v-if="!entertainShow"></i>
                </div>
                <div v-if="entertainShow">
                  <div class="check-row row">
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.bluetooth_speaker">Bluetooth Speakers</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.book">Books</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.dvd_player">DVD player</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.tv">TV</el-checkbox-button>
                      <div class="show-margin" v-show="data.tv">
                        <p class="propety-title">TV Service</p>
                        <el-select v-model="data.tv_service" placeholder="Please choose">
                          <el-option v-for="item in tvServiceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                      </div>
                      <div class="show-margin" v-show="data.tv">
                        <p class="propety-title">TV Number</p>
                        <el-select v-model="data.tv_quantity"><el-option v-for="item in 100" :key="item" :label="item" :value="item">
                          </el-option></el-select>
                      </div>
                    </div>
                  </div>
                  <div class="set_width" v-show="data.tv">
                    <div class="propety_row"></div>
                  </div>
                </div>
              </div>
              <!-- Kitchen -->
              <div>
                <div class="top-prodect" @click="kitchenShow = !kitchenShow">
                  <span class="title-text">Kitchen</span><i class="el-icon-arrow-down" v-if="kitchenShow"></i>
                  <i class="el-icon-arrow-right" v-if="!kitchenShow"></i>
                </div>
                <div v-if="kitchenShow">
                  <div class="check-row row">
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.bbq_tool">BBQ Tools</el-checkbox-button
                      >
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.blender">Blender</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.coffee_maker">Coffee Maker</el-checkbox-button>
                      <div v-show="data.coffee_maker" class="show-margin">
                        <el-form-item>
                          <p class="propety-title">Coffee Maker Type</p>
                          <el-select v-model="data.coffee_maker_type" placeholder="Please choose" no-data-text="No data">
                            <el-option v-for="item in coffeeMakerTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.dish_or_cup_or_utensil">Dishes,Cups & Utensils</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.dishwasher">Dishwasher</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.food_processor">Food Processor</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.ice_maker">Ice Maker</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.ice_trays">Ice Trays</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.lobster_pot">Lobster Pot</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.lobster_utensil">Lobster Utensils</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.microwave">Microwave</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.oven">Oven</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.pot_or_pan">Pots and Pans</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.refrigerator">Refrigerator</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.stove">Stove</el-checkbox-button>
                      <div v-show="data.stove" class="show-margin">
                        <el-form-item>
                          <p class="propety-title">Stove Type</p>
                          <el-select v-model="data.stove_type" placeholder="Please choose" no-data-text="No data">
                            <el-option v-for="item in stoveTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.tea_kettle">Tea Kettle</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.toaster">Toaster</el-checkbox-button >
                      <div v-show="data.toaster" class="show-margin">
                        <el-form-item>
                          <p class="propety-title">Toaster Type</p>
                          <el-select v-model="data.toaster_type" placeholder="Please choose" no-data-text="No data" >
                            <el-option v-for="item in toastTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Outdoors  -->
              <div>
                <div class="top-prodect" @click="outdoorShow = !outdoorShow">
                  <span class="title-text">Outdoors</span><i class="el-icon-arrow-down" v-if="outdoorShow"></i>
                  <i class="el-icon-arrow-right" v-if="!outdoorShow"></i>
                </div>
                <div v-if="outdoorShow">
                  <div class="check-row row">
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.beach_chair">Beach Chairs</el-checkbox-button>
                      <div v-show="data.beach_chair" class="show-margin">
                        <p class="propety-title">Quantity</p>
                        <el-select v-model="data.beach_chair_quantity" placeholder>
                          <el-option v-for="item in 10" :key="item" :label="item" :value="item">
                          </el-option>
                          <el-option label="10+" value="10+"></el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.beach_towel">Beach Towels</el-checkbox-button>
                      <div v-show="data.beach_towel" class="show-margin">
                        <p class="propety-title">Quantity</p>
                        <el-select v-model="data.beach_towel_quantity" placeholder>
                          <el-option v-for="item in 10" :key="item" :label="item" :value="item">
                          </el-option>
                          <el-option label="10+" value="10+"></el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.beach_umbrella">Beach Umbrella</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.bicycle">Bicycles</el-checkbox-button>
                      <div v-show="data.bicycle" class="show-margin">
                        <el-form-item>
                          <p class="propety-title">Quantity</p>
                          <el-select v-model="data.bicycle_quantity" placeholder="Please choose">
                            <el-option v-for="(item, index) in 100" :key="index" :value="index" >{{ index }}</el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.cooler">Cooler</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.deck">Deck</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.grill">Grill / BBQ</el-checkbox-button>
                      <div v-show="data.grill" class="show-margin">
                        <el-form-item>
                          <p class="propety-title">Grill Type</p>
                          <el-select v-model="data.grill_type" placeholder="Please choose">
                            <el-option v-for="item in grillTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.hot_tub_or_spa">Hot Tub / Spa</el-checkbox-button>
                    </div>

                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.lawn_or_garden">Lawn / Garden</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.outdoor_furniture">Outdoor Furniture</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.outdoor_shower">Outdoor Shower</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.patio">Patio</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.pool">Pool</el-checkbox-button>
                      <div v-show="data.pool" class="show-margin">
                        <el-form-item>
                          <p class="propety-title">Pool Type</p>
                          <el-select v-model="data.pool_type" placeholder="Please choose">
                            <el-option v-for="item in poolTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.porch">Porch</el-checkbox-button>
                    </div>
                    <div class="checkbox-item">
                      <el-checkbox-button v-model="data.tennis_court">Tennis Court</el-checkbox-button>
                      <div v-show="data.tennis_court" class="show-margin">
                        <el-form-item>
                          <p class="propety-title">Tennis Court Type</p>
                          <el-select
                            v-model="data.tennis_court_type"
                            placeholder="Please choose">
                            <el-option v-for="item in tennisCourtType" :key="item.id" :label="item.name" :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--bedrooms  -->
              <div>
                <div class="top-prodect" @click="bedroomShow = !bedroomShow">
                  <span class="title-text">Bedroom</span><i class="el-icon-arrow-down" v-if="bedroomShow"></i>
                  <i class="el-icon-arrow-right" v-if="!bedroomShow"></i>
                </div>
                <div v-if="bedroomShow">
                  <div class="set_width">
                    <div class="propety_row top_row">
                      <div class="propety_type">
                        <div class="propety_row top_row">
                          <div class="propety_type">
                            <p class="propety-title">How many Bedrooms</p>
                            <el-select v-model="data.bedroom_number" @change="bedroomNumberChange" placeholder>
                              <el-option v-for="item in 100" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-for="(item, index) in data.bedrooms" :key="index">
                    <div class="set_width" style="margin: 0">
                      <div>
                        <h3 class="room-small-title">
                          Bedroom&nbsp;{{ index + 1 }}
                        </h3>
                      </div>
                    </div>
                    <div class="check-row row" style="margin-top: 10px">
                      <div class="checkbox-item">
                        <el-checkbox-button v-model="item.en_suite">En suite</el-checkbox-button>
                      </div>
                    </div>
                    <div class="set_width">
                      <div class="propety_level" style="max-width:590px;">
                          <el-form-item :prop="'bedrooms.' + index + '.floor_level'" >
                             <p class="propety-title">Floor Level</p>
                              <el-select v-model="item.floor_level" placeholder="">
                                  <el-option v-for="item in floorLevelList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                               </el-select>
                            </el-form-item>
                        </div>
                      <div class="propety_icon" v-for="(bed, i) in item.beds" :key="i">
                        <div class="propety_row">
                          <div class="propety_type property_bed">
                            <div class="propety_row">
                              <div class="propety_type">
                                <el-form-item :prop=" 'bedrooms.' + index + '.beds.' + i + '.type' ">
                                  <p class="propety-title">Type of Bed</p>
                                  <el-select v-model="bed.type" placeholder="">
                                    <el-option v-for="item in bedTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                  </el-select>
                                </el-form-item>
                              </div>
                              <div class="propety_type">
                                <div class="propety_row bed_row">
                                  <el-form-item class="bed_number" :prop=" 'bedrooms.' + index +  '.beds.' + i + '.number' " >
                                  <p class="propety-title">Number of Beds</p>
                                  <el-select v-model="bed.number"  placeholder="">
                                    <el-option v-for="(item, index) in 10"  :key="index" :label="index" :value="index"></el-option>
                                  </el-select>
                                </el-form-item>
                                <span @click="removeBed(item.beds, i)" class="remove-btn" v-show="i">
                                <i class="el-icon-circle-close"></i>
                              </span>
                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="increase_btn">
                      <a class="btn-add" @click="addBed(item.beds)">+ Add</a>
                    </div>
                  </div>
                </div>
              </div>
              <!-- bathroom -->
              <div>
                <div class="top-prodect" @click="bathroomShow = !bathroomShow">
                  <span class="title-text">Bathroom</span
                  ><i class="el-icon-arrow-down" v-if="bathroomShow"></i>
                  <i class="el-icon-arrow-right" v-if="!bathroomShow"></i>
                </div>
                <div v-if="bathroomShow">
                  <div class="set_width">
                    <div class="propety_row">
                      <div class="propety_type">
                        <div class="propety_row">
                          <div class="propety_type">
                            <p class="propety-title">How many Bathrooms</p>
                            <el-select v-model="data.bathroom_number" @change="bathroomNumberChange" placeholder="Number of bedrooms">
                              <el-option v-for="item in 100" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                          </div>
                          <div class="propety_type">
                            <p class="propety-title">How many Half Bathrooms</p>
                            <el-select  v-model="data.half_bathroom_number" placeholder="" >
                              <el-option v-for="(item,index) in 101" :key="index" :label="index" :value="index"></el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-for="(item, index) in data.bathrooms" :key="index">
                    <div class="set_width" style="margin-top: 0">
                      <div>
                        <h3 class="room-small-title">
                          Bathroom&nbsp;{{ index + 1 }}
                        </h3>
                      </div>
                    </div>

                    <div class="check-row row" style="margin-top: 10px">
                      <div class="checkbox-item">
                        <el-checkbox-button v-model="item.bidet">Bidet</el-checkbox-button>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox-button v-model="item.combination_tub">Cobination Tub</el-checkbox-button>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox-button v-model="item.toilet">Toilet</el-checkbox-button>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox-button v-model="item.tub">Tub</el-checkbox-button>
                      </div>
                      <div class="checkbox-item">
                        <el-checkbox-button v-model="item.shower">Shower</el-checkbox-button>
                      </div>
                    </div>
                    <div class="set_width">
                      <div class="propety_row">
                        <div class="propety_type">
                          <el-form-item :prop="'bathrooms.' + index + '.type'">
                            <p class="propety-title">Bathroom Type</p>
                            <el-select v-model="item.type" placeholder="">
                              <el-option v-for="item in bathroomTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                    </div>

                    <div class="relation">
                      <el-switch style="display: block" v-model="item.checked" active-color="#678993"  inactive-text="Private to a Bedroom?" @change="selectBedroom(item, index)">
                      </el-switch>
                    </div>

                    <div class="set_width" v-show="item.checked">
                      <div class="propety_row">
                        <div class="propety_type">
                          <div class="propety_row">
                            <div class="propety_type">
                              <el-form-item>
                                <p class="propety-title">
                                  Associated with which Bedroom?
                                </p>
                                <el-select v-model="item.index" @change="matchBedroom(index, item.index)" placeholder="">
                                  <el-option v-for="item in bedrooms" :key="item.id" :label="item.key" :value="item.id"></el-option>
                                </el-select>
                              </el-form-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 选择类型 -->
          <div class="sg_cate" v-if="$route.params.id">
            <el-radio-group v-model="action" @change="selectAction">
              <el-radio label="publish">PUBLISH</el-radio>
              <el-radio label="hide">HIDE FROM PUBLIC</el-radio>
              <el-radio label="deactivate">DEACTIVATE</el-radio>
            </el-radio-group>
          </div>
          <div class="text-reason" v-show="showReason">
            <p>Text reason for deactivation</p>
            <el-input v-model="deactivated_reason" />
          </div>
        </el-form>
      </div>
      <div class="btn-save">
        <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="fullscreenLoading"><p class="row btn-row">
          <span>Save/Update</span><i class="el-icon-right"></i></p></el-button>
      </div>
    </div>
    <!-- 保存的按钮 -->
    <div class="propety_save" @click="save" v-if="btnFlag">
      <el-tooltip class="item" effect="dark" content="Save" placement="bottom">
        <img src="../../assets/img/save.png" alt />
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PropertyWeek from "./Property/PropertyWeek"
import { apiGetListing, apiCreateListing, apiUpdateListing, apiPublishListing, apiHideListing, apiDeactivateListing, apiUploadFile} from "../../API/api";
import { Minixs } from "../../js/mixins";
export default {
  components:{ PropertyWeek },
  mixins: [Minixs],
  data() {
    return {
      loadingFile:false,
      fan_number: "Yes",
      locationShow: true,
      decsShow: true,
      generalShow: true,
      entertainShow: true,
      kitchenShow: true,
      outdoorShow: true,
      diningShow: true,
      // descShow: true,
      bedroomShow: true,
      bathroomShow: true,
      showReason: false,
      deactivated_reason: "",
      fullscreenLoading: false,
      btnFlag: true,
      fileName: "Attached Files", // 文件名
      fileUrl: "", // 文件访问地址
      action: "hide",
      map: {}, // 用来存放房间和浴室的对应关系
      data: {
        bedroom_number: 1,
        bedrooms: [
          {
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          },
        ],
        bathroom_number: 1,
        half_bathroom_number:0,
        bathrooms: [
          {
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          },
        ],
        // Location Details
        waterfront: false,
        water_view: false,
        // General Information
        air_conditioning: false,
        clothes_dryer: false,
        fan: false,
        fireplace: false,
        gym: false,
        hair_dryer: false,
        heating: false,
        iron: false,
        ironing_board: false,
        jacuzzi: false,
        linen: false,
        parking: false,
        washing_machine: false,
        wifi: false,
        safe: false,
        towel: false,
        ac_types:[],
        // Dining
        dining_area: false,
        outdoor_dining_area: false,
        counter_seating: false,
        dining_room: false,
        // Entertainment
        bluetooth_speaker: false,
        book: false,
        dvd_player: false,
        tv: false,
        // Kitchen
        bbq_tool: false,
        blender: false,
        coffee_maker: false,
        dish_or_cup_or_utensil: false,
        dishwasher: false,
        food_processor: false,
        ice_maker: false,
        ice_trays: false,
        lobster_pot: false,
        lobster_utensil: false,
        oven: false,
        pot_or_pan: false,
        refrigerator: false,
        stove: false,
        tea_kettle: false,
        toaster: false,
        microwave: false,
        // Outdoors
        beach_towel: false,
        beach_chair: false,
        beach_umbrella: false,
        bicycle: false,
        cooler: false,
        deck: false,
        grill: false,
        hot_tub_or_spa: false,
        lawn_or_garden: false,
        outdoor_furniture: false,
        outdoor_shower: false,
        patio: false,
        pool: false,
        porch: false,
        tennis_court: false,
      },
      rules: {
        required: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur","change"],
          },
        ],
      },
      availabilities:[],
    };
  },
  methods: {
    ...mapActions([
      "getAreas",
      "getPropertyPriorityList",
      "getACTypeList",
      "getCompanyList",
      "getHeatingTypeList",
      "getTVServiceList",
      "getCoffeeMakerTypeList",
      "getStoveTypeList",
      "getToastTypeList",
      "getGrillTypeList",
      "getPoolTypeList",
      "getFloorLevelList",
      "getBedTypeList",
      "getBathroomTypeList",
      "getTennistCourtList",
    ]),
    selectAction(val) {
      if (val === "deactivate") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    // 修改Action动作
    changeAction(res) {
      if (res.deleted_at) {
        this.action = "deactivate";
        this.deactivated_reason = res.deactivated_reason;
        this.showReason = true;
      } else if (res.publish) {
        this.action = "publish";
        this.showReason = false;
      } else {
        this.action = "hide";
        this.showReason = false;
      }
    },
    // 数据格式转换
    changeDate(val) {
      let res = Object.assign({},val)
      // 转换枚举类型
      if (res.occupancy_tax_file) {
        this.fileName = res.occupancy_tax_file.file_name;
        this.fileUrl = res.occupancy_tax_file.url;
        res.occupancy_tax_file = res.occupancy_tax_file.occupancy_tax_file_uuid;
      }
      if (res.priority) res.priority = res.priority.id;
      if (res.area) res.area = res.area.id;
      if (res.ac_types) {
        let arr=[]
        res.ac_types.forEach(item => {
          arr.push(item.id)
        });
        res.ac_types=arr
        };
      if (res.heating_type) res.heating_type = res.heating_type.id;
      if (res.tv_service) res.tv_service = res.tv_service.id;
      if (res.coffee_maker_type)
        res.coffee_maker_type = res.coffee_maker_type.id;
      if (res.stove_type) res.stove_type = res.stove_type.id;
      if (res.toaster_type) res.toaster_type = res.toaster_type.id;
      if (res.grill_type) res.grill_type = res.grill_type.id;
      if (res.pool_type) res.pool_type = res.pool_type.id;
      if (res.bedrooms.length === 0) {
        for (let i = 0; i < res.bedroom_number; i++) {
          res.bedrooms.push({
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          });
        }
      } else {
        for (let i = 0; i < res.bedrooms.length; i++) {
          res.bedrooms[i].floor_level = res.bedrooms[i].floor_level.id;
          let beds = res.bedrooms[i].beds;
          for (let j = 0; j < beds.length; j++) {
            res.bedrooms[i].beds[j].type = beds[j].type.id;
          }
        }
      }

      for (let i = 0; i < res.bathrooms.length; i++) {
        res.bathrooms[i].type = res.bathrooms[i].type.id;
      }
      // 关联浴室和房间
      if (res.bathrooms.length === 0) {
        for (let i = 0; i < res.bathroom_number; i++) {
          res.bathrooms.push({
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          });
        }
      } else {
        for (let i = 0; i < res.bathrooms.length; i++) {
          if (res.bathrooms[i].bedroom) {
            res.bathrooms[i]["checked"] = true;
            res.bedrooms.forEach((bedroom, index) => {
              if (res.bathrooms[i].bedroom === bedroom.bedroom_uuid) {
                res.bathrooms[i]["index"] = index;
                this.map[i] = index;
              }
            });
          } else {
            res.bathrooms[i]["checked"] = false;
            res.bathrooms[i]["index"] = "";
          }
        }
      }

      // 删除不能传空值的key
      delete res.homeowner;
      // 删除该页面不会用到的key
      delete res.images;
      delete res.availabilities;
      delete res.rates;
      delete res.requirement;
      return res;
    },
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.availabilities = res;
        setTimeout(() => {
          if(this.$refs.retaWeek) this.$refs.retaWeek.setRatesList()
        }, 3000);
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.$store.commit("setPropertyInfor", true);
        this.$store.commit("setPropertyReuirement", true);
        this.$store.commit("setPropertyPaymentInformation", true);
        if (res.availabilities.length != 0 || res.rates.length != 0) {
          this.$store.commit("setPropertyCalendar", true);
        }
        if (res.images.length != 0) {
          this.$store.commit("setPropertyPhoto", true);
        }
        this.data = this.changeDate(res);
        this.changeAction(res);
      });
    },
    // 上传成功
    uploadFile(param) {
       let form = new FormData();
      form.append("file", param.file);
      this.loadingFile = true;
      apiUploadFile(form)
        .then((res) => {
           this.data.occupancy_tax_file = res.object_uuid;
            this.fileName = res.file_name;
            this.fileUrl = res.url;
        })
        .catch((err) => {
          this.$message("Upload failure");
        })
        .finally(() => {
          this.loadingFile = false;
        });
     
    },
    // 房间数量改变
    bedroomNumberChange(val) {
      if (val < this.data.bedrooms.length) {
        // 小于
        this.data.bedrooms = this.data.bedrooms.splice(0, val);
        // 删除 map 中对应的关联
        for (let key in this.map) {
          if (this.map[key] >= val) {
            delete this.map[key];
            // 浴室中关联的房间去掉
            this.data.bathrooms[key].checked = false;
            this.data.bathrooms[key].index = "";
          }
        }
      } else {
        let num = val - this.data.bedrooms.length;
        for (let i = 0; i < num; i++) {
          this.data.bedrooms.push({
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          });
        }
      }
    },
    // 增加床位
    addBed(beds) {
      beds.push({ type: "", number: 0 });
    },
    // 删除床位
    removeBed(beds, i) {
      beds.splice(i, 1);
    },
    // 浴室数量改变
    bathroomNumberChange(val) {
      if (val < this.data.bathrooms.length) {
        // 小于
        this.data.bathrooms = this.data.bathrooms.splice(0, val);
        // 删除 map 中对应的关联
        for (let key in this.map) {
          if (key >= val) {
            delete this.map[key];
          }
        }
      } else {
        let num = val - this.data.bathrooms.length;
        for (let i = 0; i < num; i++) {
          this.data.bathrooms.push({
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          });
        }
      }
    },
    // 是否勾选私有浴室
    selectBedroom(bathroom, index) {
      if (!bathroom.checked) {
        delete this.map[index];
        bathroom.index = "";
      }
    },
    // 选择关联的卧室
    matchBedroom(bathroomIndex, bedroomIndex) {
      // bathroomIndex 为浴室的索引，bedroomIndex 为房间的索引
      this.map[bathroomIndex] = bedroomIndex; // 以浴室的索引作为key，房间的索引作为value
    },
    save() {
      this.$refs.ListingForm.validate((valid) => {
        if (valid) {
          let listing = JSON.parse(JSON.stringify(this.data));
          Object.keys(this.map)
            .reverse()
            .forEach((key) => {
              // 反序删除bathrooms中的浴室
              let bedroom = listing.bedrooms[this.map[key]];
              if (bedroom.bathrooms) {
                // 房间已经有浴室对象
                bedroom.bathrooms.push(listing.bathrooms.splice(key, 1)[0]);
              } else {
                bedroom["bathrooms"] = listing.bathrooms.splice(key, 1);
              }
            });
          this.fullscreenLoading = true;
          if (this.$route.params.id) {
            // 修改
            apiUpdateListing(this.$route.params.id, listing)
              .then(
                (res) => {
                  this.$store.commit(
                    "setListing",
                    JSON.parse(JSON.stringify(res))
                  );

                  this.$store.commit("setPropertyInfor", true);
                  if (res.availabilities.length != 0 || res.rates.length != 0) {
                    this.$store.commit("setPropertyCalendar", true);
                  }
                  if (res.images.length != 0) {
                    this.$store.commit("setPropertyPhoto", true);
                  }
                  this.data = this.changeDate(res);
                  if (this.action === "publish" && !res.publish) {
                    // 未发布才发布
                    return apiPublishListing(this.$route.params.id).then(
                      (res) => {
                        this.$router.push({
                          name: "edit-property",
                          params: { id: res.listing_id },
                        });
                        this.$message.success("Listing publish successfully");
                      }
                    );
                  } else if (
                    this.action === "deactivate" &&
                    (!res.deleted_at ||
                      res.deactivated_reason != this.deactivated_reason)
                  ) {
                    // 未禁用才禁用
                    return apiDeactivateListing(res.listing_id, {
                      deactivated_reason: this.deactivated_reason,
                    }).then((res) => {
                      this.$router.push({
                        name: "edit-property",
                        params: { id: res.listing_id },
                      });
                      this.$message.success("Listing deactivate successfully");
                    });
                  } else if (
                    this.action === "hide" &&
                    (res.publish || res.deleted_at)
                  ) {
                    // 发布或禁用才变为隐藏
                    return apiHideListing(res.listing_id).then((res) => {
                      this.$message.success("Listing hide successfully");
                      this.$router.push({
                        name: "edit-property",
                        params: { id: res.listing_id },
                      });
                    });
                  } else {
                    this.$router.push({
                      name: "edit-property",
                      params: { id: res.listing_id },
                    });
                    this.$message.success(
                      "Listing information successfully modified"
                    );
                  }
                },
                (err) => {
                  this.$message.error("Listing information modify fail");
                }
              )
              .catch((err) => {
                this.$message.error(err);
              }) .finally(() => {
                this.fullscreenLoading = false;
              });
          } else {
            // 创建
            apiCreateListing(listing)
              .then(
                (res) => {
                  this.$message.success("Listing created successfully");
                  this.$store.commit(
                    "setListing",
                    JSON.parse(JSON.stringify(res))
                  );
                  this.$store.commit("setPropertyInfor", true);
                  // this.$store.commit("setPropertyCalendar", false);
                  // this.$store.commit("setPropertyPhoto", false);
                  this.$store.commit("setPropertyReuirement", true);
                  this.$store.commit("setPropertyPaymentInformation", true);
                  // this.$store.commit("setPropertySevice", false);
                  this.data = this.changeDate(res);
                  this.$router.push({
                    name: "edit-property",
                    params: { id: res.listing_id },
                  });
                },
                (err) => {
                  this.$message.error("Listing created fail");
                }
              )
              .finally(() => {
                this.fullscreenLoading = false;
              });
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
        }
      });
    },
    changeStatus(selector) {
      var anchor = this.$el.querySelector(selector); // 参数为要跳转到的元素id
      document.body.scrollTop = anchor.offsetTop; // chrome
      document.documentElement.scrollTop = anchor.offsetTop; // firefox
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      let scrollHeight = window.pageYOffset || document.body.scrollHeight;
      let height = document.documentElement.scrollHeight;
      if (scrollHeight > height - 1000) {
        this.btnFlag = false;
      } else {
        if (this.$route.params.id) {
          this.btnFlag = true;
        } else {
          this.btnFlag = false;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  computed: mapState({
    areas: (state) => state.areas,
    propertyPriorityList: (state) => state.enum.propertyPriorityList,
    acTypeList: (state) => state.enum.acTypeList,
    companyList: (state) => state.enum.companyList,
    heatingTypeList: (state) => state.enum.heatingTypeList,
    tvServiceList: (state) => state.enum.tvServiceList,
    coffeeMakerTypeList: (state) => state.enum.coffeeMakerTypeList,
    stoveTypeList: (state) => state.enum.stoveTypeList,
    toastTypeList: (state) => state.enum.toastTypeList,
    grillTypeList: (state) => state.enum.grillTypeList,
    poolTypeList: (state) => state.enum.poolTypeList,
    floorLevelList: (state) => state.enum.floorLevelList,
    bedTypeList: (state) => state.enum.bedTypeList,
    bathroomTypeList: (state) => state.enum.bathroomTypeList,
    tennisCourtType: (state) => state.enum.tennisCourtType,
    // 计算卧室的数量
    bedrooms() {
      return this.data.bedrooms.map((_, i) => {
        return {
          key: "Bedroom " + (i + 1),
          id: i,
        };
      });
    },
  }),
  created() {
    if (this.areas.length === 0) {
      this.getAreas();
    }
    if (this.propertyPriorityList.length === 0) {
      this.getPropertyPriorityList();
    }
    if (this.acTypeList.length === 0) {
      this.getACTypeList();
    }
    if (this.companyList.length === 0) {
      this.getCompanyList();
    }
    if (this.heatingTypeList.length === 0) {
      this.getHeatingTypeList();
    }
    if (this.tvServiceList.length === 0) {
      this.getTVServiceList();
    }
    if (this.coffeeMakerTypeList.length === 0) {
      this.getCoffeeMakerTypeList();
    }
    if (this.stoveTypeList.length === 0) {
      this.getStoveTypeList();
    }
    if (this.toastTypeList.length === 0) {
      this.getToastTypeList();
    }
    if (this.grillTypeList.length === 0) {
      this.getGrillTypeList();
    }
    if (this.poolTypeList.length === 0) {
      this.getPoolTypeList();
    }
    if (this.floorLevelList.length === 0) {
      this.getFloorLevelList();
    }
    if (this.bedTypeList.length === 0) {
      this.getBedTypeList();
    }
    if (this.bathroomTypeList.length === 0) {
      this.getBathroomTypeList();
    }
    if (this.tennisCourtType.length === 0) {
      this.getTennistCourtList();
    }
    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   this.data = this.changeDate(
      //     JSON.parse(JSON.stringify(this.$store.state.listing))
      //   );
      //   this.changeAction(this.data);
      // } else {
        this.getListing();
      // }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./Property.scss";
</style>
